.popup {
  color: black;
}

.popup .mapboxgl-popup-content {
  max-height: 18vh;
  overflow: scroll;
  box-shadow: 6px 6px 6px rgba(1, 1, 1, 0.4);
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  align-self: center;
  border-bottom: none;
  border-top-color: white;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  align-self: center;
  border-top: none;
  border-bottom-color: white;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  align-self: center;
  border-left: none;
  border-right-color: white;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  align-self: center;
  border-right: none;
  border-left-color: white;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  align-self: flex-start;
  border-top: none;
  border-left: none;
  border-bottom-color: white;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  align-self: flex-end;
  border-top: none;
  border-right: none;
  border-bottom-color: white;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  align-self: flex-end;
  border-bottom: none;
  border-right: none;
  border-top-color: white;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  align-self: flex-start;
  border-bottom: none;
  border-left: none;
  border-top-color: white;
}

.addicon {
  width: 30px;
  height: 30px;
}

.add-center-icon {
  position: absolute !important;
  width: 24px !important;
  height: 24px !important;
  left: 50% !important;
  margin-top: -9px !important;
  margin-left: -12px !important;
  pointer-events: none !important;
}

.indicator {
  position: absolute !important;

  pointer-events: none !important;
}

.locationDisabledIcon {
  color: #000;
  width: 28px !important;
  height: 28px !important;
}

.locationButton {
  background-color: #fff !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  min-height: 40px !important;
  min-width: 40px !important;
}

.layers-icon {
  color: #000;
  width: 28px !important;
  height: 28px !important;
}

.layers-icon-button {
  background-color: #fff !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  min-height: 40px !important;
  min-width: 40px !important;
}
