h4.buttonTitle {
  margin: 0;
}
table {
  border-spacing: 0 10px;
}
td.image {
  padding: 0 10px 0 0;
}

.css-ttfa5i-MuiListItemIcon-root {
  min-width: 38px !important;
}
